import React from 'react'

//Assets
import imglogo from "../assets/img/logo-tenpo-rf.svg";
import appstore from "../assets/img/appstore.svg";
import fb from "../assets/img/Facebook.svg";
import googleplay from "../assets/img/googleplay.svg";
import insta from "../assets/img/Instagram.svg";
import youtube from "../assets/img/Youtube.svg";
import credicorp from "../assets/img/credicorp.png";

// Styled
import styled from "styled-components";
import * as ss from "styled-system";
import Text from './globales/Text.js'
import Container from "./globales/Container.js";
import { Logo } from "./globales/Logo.js";
import NavLink from './globales/NavLink.js';


const Wrapper = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${ss.color}
`;

const Colum = styled.div`
  width: 19%;

  @media only screen and (max-width: 890px) {
    width: 100%;
  }
`;



const Flex = styled.div`
  display:flex;
  justify-content:space-between;
  max-width:130px;
`;



export const Footer = () => {
    return (
      <>
        <Wrapper bg="dark.0">
          <Container size="content">
            <Logo size="footer" src={imglogo} alt="Tenpo Recarga Fácil" />        

            <Colum>
              <Text type="h3" color="primary.0">
                Conócenos
              </Text>
              <NavLink type="url" to="https://tenpo.cl/quienes-somos">¿Quiénes somos? </NavLink>
              <NavLink type="t1" to="/contacto">Contacto</NavLink>
            </Colum>
            <Colum>
              <Text type="h3" color="primary.0">
                ¿Necesitas ayuda?
              </Text>
              <NavLink type="t1" to="/ayuda">Ayuda</NavLink>
              <NavLink type="t1" to="/ayuda">Emergencias</NavLink>
              <Text type="default" color="light.0">
                Llámanos 600 006 1110
              </Text>
            </Colum>
            <Colum>
              <Text type="h3" color="primary.0">
                ¿Necesitas ayuda?
              </Text>
              <Flex>
                <img src={insta} alt="" />
                <img src={youtube} alt="" />
                <img src={fb} alt="" />
              </Flex>
            </Colum>
            <Colum onClick={()=> window.location.href='https://tenpo.page.link/UkMX' }>
              <Text type="h3" color="primary.0">
                ¿Estás listo? ¡Vamos!
              </Text>
              <img src={googleplay} alt="" style={{ margin: 5, cursor: 'pointer' }} />
              <img src={appstore} alt="" style={{ margin: 5, cursor: 'pointer' }} />
            </Colum>
          </Container>
        </Wrapper>

        <Wrapper bg="dark.0">
          <Container size="content" type="center">
            <Text type="small" color="light.0">
              Tenpo, con el respaldo de
            </Text>
            <img src={credicorp} alt="" style={{ marginLeft: 5, width: 101 }} />
          </Container>
        </Wrapper>

        <Wrapper bg="light.1">
          <Container size="content" type="center">
            <Text type="small" color="dark.2">
              Infórmese sobre las entidades autorizadas para emitir Tarjetas de
              Pago en el país, quienes se encuentran inscritas en los Registros
              de Emisores de Tarjetas que lleva la CMF, en www.cmfchile.cl
            </Text>
          </Container>
        </Wrapper>
      </>
    );
}
