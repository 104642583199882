import React, { useEffect, useState, useContext } from 'react';
import * as c from './globales/Recarga/Styles';
import ReCAPTCHA from 'react-google-recaptcha';
import { validateForm } from '../utils/Utils';
import gs from '../components/services/Global';

export default function Contacto() {
  const defaultForm = {
    name: '',
    email: '',
    phone: '',
    type: '',
    description: '',
  };

  const [captcha, setCaptcha] = useState();
  const [ticket, setTicket] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState([]);

  function onChange(value) {
    console.log('Captcha value:', value);
    setCaptcha(value);
  }

  async function submit() {
    await setErrors({
      name: form.name.length < 3,
      email: !validateForm.email(form.email),
      phone: !validateForm.identifier(form.phone, 'PHONE'),
      type: form.type < 3,
      description: form.description < 3,
    });
  }

  useEffect(() => {
    console.log('useEffect errors');
    async function validateForm() {
      let formWithError = false;
      formWithError = Object.values(errors).find((e) => {
        return e === true;
      });

      console.log('errors', errors);
      console.log('formWithError', formWithError);

      if (!formWithError && Object.keys(errors).length) {
        // API call
        let ticket = await gs.contact(form);
        console.log('ticket', ticket);
        setTicket(ticket.data.id);
        setForm(defaultForm);
      }
    }
    validateForm();
  }, [errors]);

  function simpleContact() {
    return (
      <c.SimpleContact>
        Puedes llamarnos al{' '}
        <c.Link href="tel:6000061110">600 006 1110</c.Link>
      </c.SimpleContact>
    );
  }

  return (
    <c.Gray>
      {ticket && (
        <c.Success>
          <c.SuccessClose onClick={() => setTicket()}>
            ✕
          </c.SuccessClose>
          <c.SuccessTitle>
            ¡Hemos recibido tu mensaje con éxito!
          </c.SuccessTitle>
          <c.SuccessText>
            Nos pondremos en contacto contigo a la brevedad, Número de
            Ticket: {ticket}
          </c.SuccessText>
        </c.Success>
      )}
      {!ticket && (
        <>
          <c.ContactTitle>Contacto Recarga Fácil</c.ContactTitle>
          {simpleContact()}
          {false && (
            <c.FormContact>
              <c.FormContactHeader>
                Si tienes dudas sobre alguna recarga en particular,
                indícanos el <b>número suscriptor</b>, la{' '}
                <b>compañía</b>, la <b>fecha</b> y el <b>monto</b> en
                el comentario.
              </c.FormContactHeader>

              <c.LabelContact>Nombre *</c.LabelContact>
              <c.InputTextContact
                value={form.name}
                onChange={(v) =>
                  setForm({ ...form, name: v.target.value })
                }
              ></c.InputTextContact>
              {errors.name && (
                <c.Error>Debe ingresar su nombre</c.Error>
              )}

              <c.LabelContact>Correo electrónico *</c.LabelContact>
              <c.InputTextContact
                value={form.email}
                onChange={(v) =>
                  setForm({ ...form, email: v.target.value })
                }
              ></c.InputTextContact>
              {errors.email && <c.Error>Correo inválido</c.Error>}

              <c.LabelContact>Teléfono *</c.LabelContact>
              <c.InputTextContact
                value={form.phone}
                onChange={(v) =>
                  setForm({ ...form, phone: v.target.value })
                }
              ></c.InputTextContact>
              {errors.phone && <c.Error>Teléfono inválido</c.Error>}

              <c.LabelContact>Asunto *</c.LabelContact>
              <c.SelectContact
                onChange={(v) =>
                  setForm({ ...form, type: v.target.value })
                }
              >
                <option value="" hidden>
                  Por favor elija una opción
                </option>
                <option value="Recarga fallida">
                  Recarga fallida
                </option>
                <option value="Pago duplicado">Pago duplicado</option>
                <option value="Promociones Recargas">
                  Promociones
                </option>
                <option value="Quiero mi comprobante">
                  Quiero mi comprobante
                </option>
                <option value="Sugerencias Recargas">
                  Sugerencias
                </option>
              </c.SelectContact>
              {errors.phone && (
                <c.Error>Seleccione una opción</c.Error>
              )}

              <c.LabelContact>Comentario *</c.LabelContact>
              <c.TextContact
                value={form.description}
                onChange={(v) =>
                  setForm({ ...form, description: v.target.value })
                }
              ></c.TextContact>
              {errors.description && (
                <c.Error>Escriba un comentario</c.Error>
              )}
              <c.RequiredFields>
                * Todos los campos son obligatorios.
              </c.RequiredFields>

              <c.Captcha>
                <ReCAPTCHA
                  sitekey="6Lc3aTwcAAAAAPPXKH4L4yOtaImJI5jAi6cyiIat"
                  onChange={onChange}
                />
              </c.Captcha>

              {captcha && (
                <c.ButtonContact onClick={() => submit()}>
                  Enviar
                </c.ButtonContact>
              )}
            </c.FormContact>
          )}
        </>
      )}
    </c.Gray>
  );
}
